.bill_content {
  width: 50%;

  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
}
.buy_bill_content {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* This will create 4 columns */
  gap: 20px; /* Adjust gap between grid items as needed */
}
@media (max-width: 768px) {
  /* Adjust breakpoint as needed */
  .buy_bill_content {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Adapt to 3 columns for smaller screens or second row */
  }
}
.bill_header {
  display: flex;
  justify-content: space-between;
}
.bill_header_text {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 50px;
}
.bill_header_btn {
  color: #ffffff;
  margin-top: 5px;
  font-size: 16px;
  background-color: #333c44;
  padding: 10px, 20px, 10px, 20px;
  border-radius: 100px;
  width: 107px;
  height: 40px;
}
.provider_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
}
.bill_icon_img {
  justify-content: center;
  align-self: center;
}
.bill_input {
  background-color: #f5f5f5;
  width: 400px;
  height: 50px;
  border-radius: 13px;
  border: none;
  padding-left: 20px;
  margin-top: 25px;
}
.bill_input:focus-visible {
  outline: none !important;
}
.bill_btn {
  font-size: 16px;
  line-height: 20px;
  background-color: #c17b31;
  border-radius: 0px, 20px, 20px, 0px;
  color: #ffffff;
  box-shadow: 4px 0px 10px 0px #0000001a;
  width: 400px;
  height: 50px;
  border-radius: 13px;
  border: none;
  margin-top: 35px;
}
.save_beneficiary {
  margin-top: 20px;
  display: flex;

  align-items: center;
}
.beneficiary_label {
  margin-left: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #c17b31;
}

input:focus + .slider {
  box-shadow: 0 0 1px #c17b31;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.icon_container {
  border-radius: 10px;
  background-color: #e7e7e7;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bill_container {
  width: 170px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 35px 32px; */
  gap: 32px;
  border-radius: 20px;
  box-shadow: 0px 26px 10px 5px #0000001a;
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for the shadow */
}

.bill_container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Example shadow effect */
}
.bill_icon_text {
  font-size: 14px;
  color: #000000;
}
