.PaycableTV_content {
  width: 90%;
}
.buy_PaycableTV_content {
  width: 35%;
  height: 50vh;
  box-shadow: 4px 0px 10px 0px #0000001a;
  border-radius: 0px 20px 20px 0px;
  padding: 35px 50px 0 70px;
}
.PaycableTV_header {
  display: flex;
  justify-content: space-between;
}
.PaycableTV_header_text {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 50px;
}
.PaycableTV_header_btn {
  color: #ffffff;
  margin-top: 5px;
  font-size: 16px;
  background-color: #333c44;
  padding: 10px, 20px, 10px, 20px;
  border-radius: 100px;
  width: 107px;
  height: 40px;
}
.provider_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
}
.provider_img {
  margin-right: 30px;
  margin-top: 10px;
}
.PaycableTV_input {
  background-color: #f5f5f5;
  width: 400px;
  height: 50px;
  border-radius: 13px;
  border: none;
  padding-left: 10px;
  margin-top: 25px;
}
.PaycableTV_input:focus-visible {
  outline: none !important;
}
.PaycableTV_btn {
  font-size: 16px;
  line-height: 20px;
  background-color: #c17b31;
  border-radius: 0px, 20px, 20px, 0px;
  color: #ffffff;
  box-shadow: 4px 0px 10px 0px #0000001a;
  width: 400px;
  height: 50px;
  border-radius: 13px;
  border: none;
  margin-top: 35px;
}
.PaycableTV_flight_btn {
  background-color: #c17b31;
  border-radius: 10px;
  border: transparent;
  padding: 10px;
  color: white;
}

.save_beneficiary {
  margin-top: 20px;
  display: flex;

  align-items: center;
}
.beneficiary_label {
  margin-left: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #c17b31;
}

input:focus + .slider {
  box-shadow: 0 0 1px #c17b31;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.input-destination {
  background-image: url("../../assets/images/Icon/airplane.png"); /* Adjust the path to your icon */
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-size: 20px; /* Adjust based on the size of your icon */
  margin-bottom: 20px;
  margin-right: 20px;
}
.PaycableTV_input_d {
  background-color: #f5f5f5;
  width: 400px;
  height: 50px;
  border-radius: 13px;
  border: none;
  padding-left: 30px;
  margin-top: 25px;
}

.custom-date-icon {
  position: relative;
}

.custom-date-icon .date-icon-overlay {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  pointer-events: none; /* Allows click to pass through to the input */
}

.date-input-width {
  /* Ensure the input has padding to not overlap the icon */
  padding-right: 30px; /* Adjust based on the size of your icon */
}
.dropdown-toggle-margin {
  margin-right: 20px;
}
.dropdown-container {
  background-color: #f8f9fa; /* Soft gray background */
  padding: 20px; /* Optional: Added padding for visual spacing */
}
.input-exchange-container {
  display: flex;
  align-items: center; /* Centers items vertically */
  justify-content: start; /* Adjust this to get the desired spacing */
  position: relative; /* Allows absolute positioning relative to this container */
  margin-bottom: 20px;
}

.exchange_icon_button {
  background: #f8f9fa;
  border: none;
  cursor: pointer;
  position: absolute; /* Position the button absolutely within the flex container */
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%); /* Adjust horizontal centering */
  z-index: 2; /* Ensure it's above the inputs */
  margin-left: 200px;
  margin-top: -5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.PaycableTV_input {
  flex-grow: 1; /* Inputs take up equal space */
}
.PaycableTV_input.dropdown-toggle {
  width: 100%; /* Match input field width */
  background-color: #ffffff; /* Input field background color */
  border: 1px solid #ced4da; /* Similar to Bootstrap's form input border */
  border-radius: 0.25rem; /* Match input field border-radius */
  padding: 0.375rem 0.75rem; /* Input field padding */
  font-size: 1rem; /* Input field font size */
  line-height: 1.5; /* Input field line height */
  color: #495057; /* Input field text color */
}

/* Adjusting the dropdown menu if necessary */
.PaycableTV_input .dropdown-menu {
  width: 100%; /* Ensure the dropdown menu matches the width of the toggle */
}

.custom-select-color,
.custom-select-color option {
  color: #c17b31;
}
.custom-select-color_g,
.custom-select-color_g option {
  color: #7a7a7a;
}

.form-background {
  background-color: #f5f5f5;
}
.select-background {
  background-color: #f5f5f5;
}
