.footer {
  /* width: 97.9vw; */
  /* height: 42.3125rem; */
  flex-shrink: 0;
  border-radius: 3.125rem 3.125rem 0rem 0rem;
  background: linear-gradient(103deg, #ed9232 0%, #814403 99.18%);

  .info__section,
  .legal__section,
  .social__section,
  .get__in__touch__section {
    ul {
      color: #fff;
      font-size: 1.67194rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      white-space: nowrap;
    }
    li {
      img {
        padding-right: 5px;
      }
      color: #fff;
      font-size: 1.04494rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      list-style: none;
      padding: 10px 0;
      cursor: pointer;
    }
  }
  .download__from__footer {
    width: 350px;
    height: 200px;
    background: #c17b31;
    border-radius: 1.81238rem;
    background: rgba(255, 255, 255, 0.1);
    padding: 25px;

    h2 {
      color: var(--white, #fff);
      font-size: 0.81556rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.17769rem;
      letter-spacing: 0.04075rem;
      text-transform: uppercase;
    }
    p {
      color: var(--white, #fff);
      font-size: 0.81556rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.45006rem;
    }

    .download__cta {
      display: flex;
      align-items: center;
      justify-content: space-around;

      button {
        border-radius: 3.62475rem;
        background: #c17b31;
        color: var(--white, #fff);
        text-align: center;
        font-size: 0.72494rem;
        font-style: normal;
        font-weight: 700;
        line-height: 0.72494rem;
        letter-spacing: 0.0725rem;
        text-transform: uppercase;
        white-space: nowrap;
        padding: 15px 10px;
      }
    }
  }

  .social__icons {
    display: flex;
    flex-direction: row-reverse;
    padding: 20px 0;
    cursor: pointer;
    img {
      margin: 5px 75px 5px 5px;
    }
  }
  .line {
    border: 1px solid #fff;
    width: 90%;
    margin: 0 auto;
  }
  .rights {
    span {
      color: #fff;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 5px 0px 5px 70px;
    }
  }
  .terms__privacy__cookies {
    span {
      margin: 5px 75px 5px 0px;
      color: #fff;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }
}
