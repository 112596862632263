.active {
  color: #c17b31;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sticky-header {
  position: sticky;
  top: 0;
  padding: 10px;
  transition: background-color 0.3s ease;
  z-index: 100;
}
.sticky-header.scrolled {
  background-color: #3333;
}
.nav-item {
  padding: 0 15px;
}
.nav-link:focus,
.nav-link:hover {
  color: #c17b31;
}
.nav-link {
  color: #929292;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.main__header {
  display: flex;
  justify-content: space-between;
  padding: 0 160px 0 100px;
}
.auth__facility {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.sign__in {
  a {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }
  padding: 0 15px;
}
.create__account {
  a {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    background: #c17b31;
    color: #fff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  button:hover {
    color: #c17b31;
    background: #fff;
  }
}
