.dashboard_content {
  padding-top: 55px;
  padding-left: 50px;
  /* display: flex;
  justify-content: space-around;
  flex-wrap: wrap; */
}
.dashboard_card__text {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  line-height: 4.375rem;
  font-size: 15px;
}
.dashboard_card {
  width: 300px;
  height: 200px;
  border-radius: 20px;
  border: 1px solid #00000040;
  box-shadow: 5.559183597564697px 5.559183597564697px 27.795917510986328px 0px
    #a3a3a333;
  cursor: pointer;
  text-align: center;
  padding-top: 25px;
}
.dashboard_card:hover {
  border: 1px solid #c17b3140;
  box-shadow: 5.559183597564697px 5.559183597564697px 27.795917510986328px 0px
    #a3a3a333;
}
.card_title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.virtual_card {
  background: linear-gradient(to right, #495258, #1c262e);
  box-shadow: 5.559183597564697px 5.559183597564697px 27.795917510986328px 0px
    #a3a3a333;
  padding: 30px 40px 0 50px;
  font-weight: 700;
}
.card_num {
  color: #ffffff;
  text-align: left;
  line-height: 17.5px;
  font-size: 14px;
  margin-top: 70px;
}
.card_date {
  color: #ffffff;
  text-align: left;
  line-height: 17.5px;
  font-size: 14px;
  margin-top: 10px;
}
/* Dashboard.css */
.dashboard_card:hover {
  -webkit-box-shadow: 0px 28px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 28px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease 0s;
}
.card_item {
  cursor: none;
}
