/* SearchResults.css */
.bookings_content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
}

.search_result_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 10px);
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 30px;
  gap: 10px;
}

.airline_logo_container,
.economy_info_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
}

.time_info_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time_info,
.nonstop_info {
  text-align: center;
}

.nonstop_line {
  background: #000;
  width: 200px;
  height: 1px;
}

.vertical_divider {
  background: #0004;
  width: 1px;
  height: 100px;
}

.economy_label {
  color: #c17b31;
}

.bookings_flight_btn {
  background-color: #c17b31;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}
