.nav_container {
  background-color: #485157;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  /* width: 280px; */
}

.divider1 {
  margin-top: 100px;
  width: 230px;
  height: 1px;
  background-color: #ffffff40;
}
.divider2 {
  margin-top: 20px;
  width: 230px;
  height: 1px;
  background-color: #ffffff40;
}
.nav_button {
  margin-top: 30px;
  width: 240px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  padding: 10px 15px 15px 15px;
  align-items: center;
  justify-content: left;
  cursor: pointer;
}
.nav_button:hover,
.nav_button:active {
  background-color: #c17b31;
}
.nav_button_img {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-top: 3px;
}
.nav_button_txt {
  font-size: 16px;
  margin-left: 20px;
  color: #ffffff;
}
