/* Discover */
.discover {
  /* width: 85.5vw; */
  margin: 0 0 0 50px;

  .discover__heading {
    color: #000;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.375rem;
    letter-spacing: -0.08rem;
  }

  .hightlight__text {
    color: #c17b31;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.375rem;
    letter-spacing: -0.08rem;
  }
  .discover__description {
    color: #808080;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .store__button {
    margin-top: 50px;
    width: 16.17363rem;
    height: 4.91806rem;
    flex-shrink: 0;
    border-radius: 0.27919rem;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px 0 0;

    .info__line {
      color: #fff;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .store__line {
      color: #fff;
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .image__ellipse {
    .ellipse28 {
      padding: 0px 0 41px 20px;
    }
  }
  .ellipse31 {
    padding-top: 260px;
    text-align: right;
  }
  .ellise32 {
    padding-top: 150px;
    text-align: right;
  }
  .bottom__ellipse {
    width: 8%;
    margin: 0 auto;
  }

  .second__row {
    margin: 15px 0;
  }
}

/* Services Page */
.service__page {
  /* width: 85.5vw; */
  margin: 0 auto;

  .service__heading {
    color: #c17b31;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.375rem;
    letter-spacing: 0.3rem;
  }
  .service__subheading {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.125rem;
    letter-spacing: -0.06rem;
  }

  .service {
    display: flex;
    justify-content: center;

    .single__service {
      margin: 100px 29px;
      width: 12.5625rem;
      height: 13.5rem;
      border-radius: 0.625rem;
      box-shadow: 0px 18px 40px 0px rgba(0, 0, 0, 0.13),
        0px 73px 73px 0px rgba(0, 0, 0, 0.11),
        0px 165px 99px 0px rgba(0, 0, 0, 0.07),
        0px 293px 117px 0px rgba(0, 0, 0, 0.02),
        0px 458px 128px 0px rgba(0, 0, 0, 0);
      text-align: center;
      padding: 85px 0;
      background: #fff;
      color: #000;
    }
    .single__service:hover {
      color: #fff;
      background: linear-gradient(129deg, #ff9f39 3.01%, #af5b03 98.36%);
    }

    .service__label {
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 10px 0;
    }
  }
}

/* About us Page */
.about__us__page {
  padding: 0 130px;
  .about__us {
    margin: 100px 0;
    span {
      color: #c17b31;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4.375rem;
      letter-spacing: 0.3rem;
    }
    h1 {
      color: #000;
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4.375rem;
      letter-spacing: -0.06rem;
    }
    p {
      color: #929292;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem;
    }
    .learn__more {
      color: #c17b31;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem;
      cursor: pointer;
    }
  }
  .image__with__gradiant {
    mix-blend-mode: darken;
    width: 50%;
  }
}

/* What we do */
.what__we__do__page {
  /* width: 85.5vw; */
  margin: 0 auto;
  .what__we__do__heading {
    margin: 100px 0;
    span {
      color: #000;
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4.375rem;
      letter-spacing: -0.06rem;
    }
  }
  .what__we__do {
    display: flex;
    justify-content: center;
    margin: 100px 0;
    .single__what__we__do {
      margin: 0 21px;
      padding: 65px 0;
      width: 17.65175rem;
      height: 21.72525rem;
      border-radius: 2.1725rem;
      background: #fff;
      .single__what__we__do__label {
        padding: 20px 0;
        color: #000;
        font-size: 0.97763rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.41169rem;
        letter-spacing: 0.04888rem;
        text-transform: uppercase;
      }
      .single__what__we__do__desc {
        padding: 20px 0;
        color: #a4a4a4;
        text-align: center;
        font-size: 0.869rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.41213rem;
      }
    }
    .single__what__we__do:hover {
      border-radius: 2.1725rem;
      background: #fff;
      box-shadow: 0px 33.022px 72.997px 0px rgba(0, 0, 0, 0.1),
        0px 132.959px 132.959px 0px rgba(0, 0, 0, 0.09),
        0px 299.809px 179.885px 0px rgba(0, 0, 0, 0.05),
        0.869px 533.573px 212.908px 0px rgba(0, 0, 0, 0.01),
        0.869px 833.381px 232.895px 0px rgba(0, 0, 0, 0);
    }
  }
}

/* download App */

.download__app {
  margin: 100px 0;
  img {
    width: 100%;
  }
}

/* More Features */

.more__features {
  /* width: 85.5vw; */
  margin: 0 auto;
  /* margin: 100px 0; */
  .more__features__heading {
    color: #c17b31;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.375rem;
    letter-spacing: 0.3rem;
  }
  h1 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.125rem;
    letter-spacing: -0.06rem;
  }

  .features {
    display: flex;
    justify-content: center;
    margin: 100px 0;
    .single__feature {
      text-align: center;
      margin: 0 21px;
      padding: 65px 0;
      width: 17.65175rem;
      height: 21.72525rem;
      border-radius: 2.1725rem;
      background: #fff;
      .single__feature__label {
        padding: 20px 0;
        /* color: #000; */
        font-size: 0.97763rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.41169rem;
        letter-spacing: 0.04888rem;
        text-transform: uppercase;
      }
      .single__feature__desc {
        padding: 20px 0;
        color: #a4a4a4;
        text-align: center;
        font-size: 0.869rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.41213rem;
      }
    }
    .single__feature:hover {
      border-radius: 2.1725rem;
      background: #c17b31;
      color: #fff;
      box-shadow: 0px 33.022px 72.997px 0px rgba(0, 0, 0, 0.1),
        0px 132.959px 132.959px 0px rgba(0, 0, 0, 0.09),
        0px 299.809px 179.885px 0px rgba(0, 0, 0, 0.05),
        0.869px 533.573px 212.908px 0px rgba(0, 0, 0, 0.01),
        0.869px 833.381px 232.895px 0px rgba(0, 0, 0, 0);
      .single__feature__desc,
      .learn__more {
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .learn__more {
    color: #c17b31;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    cursor: pointer;
  }
  .get__started {
    text-align: center;
    display: flex;
    justify-content: center;
    button {
      display: flex;
      padding: 1.25rem 1.875rem;
      justify-content: center;
      align-items: center;
      border-radius: 6.25rem;
      background: #c17b31;
      box-shadow: 0px 16px 34px 0px rgba(0, 0, 0, 0.1),
        0px 62px 62px 0px rgba(0, 0, 0, 0.09),
        0px 140px 84px 0px rgba(0, 0, 0, 0.05),
        0px 248px 99px 0px rgba(0, 0, 0, 0.01),
        0px 388px 109px 0px rgba(0, 0, 0, 0);
      color: #fff;
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.625rem;
      border: none;
    }
  }
}

/* Testimonials */

.testimonials {
  margin: 100px 0;
  .testimonials__heading {
    color: #c17b31;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.375rem;
    letter-spacing: 0.3rem;
  }
  h1 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.125rem;
    letter-spacing: -0.06rem;
  }
  .feedback {
    margin: 100px 0;
  }
  .single__fedback {
    text-align: left;
    margin: 50px 21px;
    padding: 30px 30px;
    /* width: 30.65175rem !important; */
    height: 15.72525rem;
    border-radius: 2.1725rem;
    background: #c17b31;
    color: #fff;
    /* margin: 0 15px; */
  }
}

/* JOin US */

.join__us {
  background: #fefdfd;
  margin: 50px 0;
  /* width: 75rem; */
  height: 35.0625rem;
  flex-shrink: 0;
  padding: 60px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;

  .mail__section {
    h2 {
      color: #000;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3.125rem;
      letter-spacing: -0.045rem;
    }
    p {
      color: #a8a8a8;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 3.125rem;
      letter-spacing: -0.025rem;
    }
    .join__us__cta {
      text-align: center;
      margin: 50px 0;
      button {
        display: flex;
        padding: 1.25rem 1.875rem;
        justify-content: center;
        align-items: center;
        border-radius: 6.25rem;
        background: #c17b31;
        box-shadow: 0px 16px 34px 0px rgba(0, 0, 0, 0.1),
          0px 62px 62px 0px rgba(0, 0, 0, 0.09),
          0px 140px 84px 0px rgba(0, 0, 0, 0.05),
          0px 248px 99px 0px rgba(0, 0, 0, 0.01),
          0px 388px 109px 0px rgba(0, 0, 0, 0);
        color: #fff;
      }
    }
  }

  input {
    border: none;
    width: 100%;
    border-bottom: 1px solid #cacaca;
    margin: 50px 0;
  }

  ::placeholder {
    color: #666;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: -0.025rem;
  }

  input:focus-within {
    padding: 15px;
  }
}
.content {
  padding-top: 80px;
}
