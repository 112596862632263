.login_logo {
  padding: 15px 220px 0 100px;
}
.login_form_wrapper {
  display: flex;
  margin-top: 60px;
}

.hightlight__text {
  color: #c17b31;
  font-style: normal;
  font-weight: 700;
  line-height: 4.375rem;
  letter-spacing: -0.08rem;
}
.login_header {
  margin: 40px 0  10px 0;
  font-size: 2rem;
  text-align: center;
}
.imageContainer {
  position: absolute;
  z-index: -10000 !important;
}

.auth_title {
  font-weight: bold;
}
.login_form {
  margin: auto;
  width: 600px;
  border-radius: 20px;
  box-shadow: 0px 24px 53px 0px #0000001A;
  padding: 0 90px 60px 90px;
}
.ellipse28 {
  position: absolute;
  margin-left: -130px;
  margin-top: -40px;
  z-index: -1;
}
.ellipse31 {
  position: absolute;
  margin-left: 600px;
  margin-top: -10px;
  z-index: -1;
}
.ellipse32 {
  position: absolute;
  margin-left: -150px;
  bottom: 18px;
  z-index: -1;
}

.auth_input {
  border-radius: 10px;
  background-color: #F5F5F5;
  border: none;
  width: 100%;
  height: 47px;
  padding-left: 33px;
  margin-top: 25px;
}
.auth_input:focus-visible {
  outline: none !important;
}
.auth_button {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    background: #c17b31;
    color: #fff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    width: 100%;
    margin-top: 20px;

}
.auth_button:hover {
  color: #c17b31;
  background: #fff;
}
.guide_text {
  text-align: center;
  margin-top: 30px;
}
.color_text a {
  color: #C17B31;
  text-decoration: none;
}