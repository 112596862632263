.airtime_content {
  width: 70%;
  /* display: flex;
  justify-content: space-around;
  flex-wrap: wrap; */
}
.buy_airtime_content {
  width: 60%;
  height: 70vh;
  box-shadow: 4px 0px 10px 0px #0000001a;
  border-radius: 0px 20px 20px 0px;
  padding: 35px 50px 0 70px;
}
.airtime_header {
  display: flex;
  justify-content: space-between;
}
.airtime_header_text {
  font-size: 25px;
  font-weight: 600;
}
.airtime_header_btn {
  color: #ffffff;
  margin-top: 5px;
  font-size: 16px;
  background-color: #333c44;
  padding: 10px, 20px, 10px, 20px;
  border-radius: 100px;
  width: 107px;
  height: 40px;
}
.provider_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
}
.provider_img {
  margin-right: 30px;
  margin-top: 10px;
}
.airtime_input {
  background-color: #f5f5f5;
  width: 400px;
  height: 50px;
  border-radius: 13px;
  border: none;
  padding-left: 20px;
  margin-top: 25px;
}
.airtime_input:focus-visible {
  outline: none !important;
}
.airtime_btn {
  font-size: 16px;
  line-height: 20px;
  background-color: #c17b31;
  border-radius: 0px, 20px, 20px, 0px;
  color: #ffffff;
  box-shadow: 4px 0px 10px 0px #0000001a;
  width: 400px;
  height: 50px;
  border-radius: 13px;
  border: none;
  margin-top: 35px;
}
.save_beneficiary {
  margin-top: 20px;
  display: flex;

  align-items: center;
}
.beneficiary_label {
  margin-left: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #c17b31;
}

input:focus + .slider {
  box-shadow: 0 0 1px #c17b31;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
